import { EventToken } from "../event/eventToken";
import { isObject } from "../validation";
import { ParticipantEventActivity } from "./participantEventActivity";

export class ParticipantEventToken extends ParticipantEventActivity {
    setToken(token){
        this.setActivity(token);
        return this;
    }
    getToken(){
        return this.getActivity();
    }
    getClone() {
        return new ParticipantEventToken().applyExport(JSON.parse(this.getExport()));
    }

    setActivity(activity) {
        if (!(activity instanceof EventToken)) {
            if (!isObject(activity)){
                throw new ParticipantEventTokenError("Invalid parameter type: token");
            }
            activity = new EventToken().applyExport(activity);
        }
        return super.setActivity(activity);
    }
}

export class ParticipantEventTokenError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventTokenError";
    }
}