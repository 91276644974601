import { EventId } from "./eventId";
import { isBoolean, isInteger, isString } from "../validation";
import { getUUID } from "../helper";

export class EventActivity extends EventId {
    #applyExport = false; // Can be used to ignore validations during clone / restoration

    static getMinPoints() {
        return 1;
    }
    static getMaxPoints() {
        return 3;
    }

    constructor() {
        super();
        this.secret = getUUID();
        this.points = EventActivity.getMinPoints();
        this.title = "";
        this.description = "";
        this.directlyAvailable = false; // Will this activity be available from the start to the user? Alternative is that it must be activated first by scanning a qr code.
    }

    isSecret(secret) {
        return this.secret === secret;
    }
    getPoints() {
        return this.points;
    }
    getTitle() {
        return this.title;
    }
    getDescription() {
        return this.description;
    }
    isDirectlyAvailable() {
        return this.directlyAvailable;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new EventActivity().applyExport(JSON.parse(this.getExport()));
    }
    getStartActivityLink(eventId) {
        throw new EventActivityError("Not implemented in base class!");
    }
    getCompleteActivityLink(eventId) {
        throw new EventActivityError("Not implemented in base class!");
    }

    applyExport(params = { id: "", points: 0, title: "", description: "", directlyAvailable: false, secret: "" }) {
        this.#applyExport = true;

        this.setId(params.id);
        this.setPoints(params.points);
        this.setTitle(params.title);
        this.setDescription(params.description);
        this.setDirectlyAvailable(params.directlyAvailable);
        this.setSecret(params.secret);

        this.#applyExport = false;
        return this;
    }

    setDirectlyAvailable(directlyAvailable = false) {
        if (!isBoolean(directlyAvailable)) {
            throw new EventActivityError("Invalid parameter type: directlyAvailable");
        }
        this.directlyAvailable = directlyAvailable;
        return this;
    }
    setPoints(points = 0) {
        if (!isInteger(points)) {
            throw new EventActivityError("Invalid parameter type: points");
        }
        this.points = points;
        return this;
    }
    setTitle(title = "") {
        if (!isString(title)) {
            throw new EventActivityError("Invalid parameter type: title");
        }
        this.title = title;
        return this;
    }
    setSecret(secret = "") {
        if (!isString(secret)) {
            throw new EventActivityError("Invalid parameter type: secret");
        }
        if (!this.#applyExport && this.secret.length > 0) {
            throw new EventActivityError("Secret already set");
        }
        this.secret = secret;
        return this;
    }
    setDescription(description = "") {
        if (!isString(description)) {
            throw new EventActivityError("Invalid parameter type: description");
        }
        this.description = description;
        return this;
    }
}

export class EventActivityError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventActivityError";
    }
}