import { getLocation } from "../logic/helper";

function Copyright() {
  return (
    <div>
      {'Copyright © '}
      <a color="text.secondary" href={getLocation()}>
        EventQuest
      </a>
      &nbsp;
      {new Date().getFullYear()}
    </div>
  );
}

export function Footer() {
  return (
    <footer className="pt-4 pb-4">
      <a color="text.secondary" variant="body2" href="/privacy">
        Privacy Policy
      </a>
      <span>
        &nbsp;•&nbsp;
      </span>
      <a color="text.secondary" variant="body2" href="/tos">
        Terms of Service
      </a>
      <Copyright />
    </footer>
  );
}
