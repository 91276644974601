export function isString(obj) {
    return (typeof obj === 'string' || obj instanceof String);
}
export function isArray(obj) {
    return Array.isArray(obj);
}
export function isInteger(obj) {
    return (obj === parseInt(obj, 10));
}
export function isDate(obj) {
    return (obj instanceof Date);
}
export function isNull(obj) {
    return obj === null;
}
export function isObject(obj) {
    return !!obj && !isArray(obj) && typeof obj === 'object';
}
export function isFunction(fn) {
    return (typeof fn == 'function')
}
export function hasFunction(obj, functionName) {
    return (!!obj && isFunction(obj[functionName]));
}
export function isJsonString(str) {
    if (!isString(str)){
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function isBoolean(bool){
    return (typeof bool == "boolean");
}