import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export function EventApiConfirmationModal({ visible, onClose, provideEventIdInput = false }) {
    const [secret, setSecret] = useState("");
    const [eventId, setEventId] = useState("");

    return (
        <Modal show={visible} fullscreen="xl-down" onHide={() => onClose("", "")} onSubmit={(e) => { e.preventDefault(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Editor Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className={`mb-3 ${!provideEventIdInput ? "d-none" : ""}`} controlId="eventId">
                        <Form.Label>Event Id</Form.Label>
                        <Form.Control type="text" placeholder="Event ID" autoFocus={provideEventIdInput} value={eventId} onChange={(e) => setEventId(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="secret">
                        <Form.Label>Secret</Form.Label>
                        <Form.Control type="password" placeholder="Event secret" autoFocus={!provideEventIdInput} value={secret} onChange={(e) => setSecret(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose("", "")}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onClose(secret, eventId)}>
                    <i className="bi bi-check-lg"></i> Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  