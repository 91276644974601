import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { EventToken } from "../../logic/event/eventToken";

export function TokenDetailModal({ tokenState, onClose }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [points, setPoints] = useState(0);
    const [token, setToken] = useState("");
    const [directlyAvailable, setDirectlyAvailable] = useState(false);
    const [hint, setHint] = useState("");

    const onOpen = () => {
        setTitle(tokenState.getTitle());
        setDescription(tokenState.getDescription());
        setPoints(tokenState.getPoints());
        setToken(tokenState.getToken());
        setDirectlyAvailable(tokenState.isDirectlyAvailable());
        setHint(tokenState.getHint());
    }

    const onSave = () => {
        tokenState.setTitle(title);
        tokenState.setDescription(description);
        tokenState.setPoints(points);
        tokenState.setToken(token);
        tokenState.setDirectlyAvailable(directlyAvailable);
        tokenState.setHint(hint);
        onClose(true, tokenState);
    }

    return (
        <Modal show={!!(tokenState)} backdrop="static" size="lg" fullscreen="xl-down" onHide={() => onClose(false)} onShow={() => onOpen()}>
            <Modal.Header closeButton>
                <Modal.Title>Token Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Token title" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Token description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="hint">
                        <Form.Label>Hint</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Token hint" value={hint} onChange={(e) => setHint(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="points">
                        <Form.Label>Points</Form.Label>
                        <Form.Control type="number" placeholder="Token points" value={points} min={EventToken.getMinPoints()} max={EventToken.getMaxPoints()} onChange={(e) => setPoints(Number.parseInt(e.target.value))} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Token</Form.Label>
                        <Form.Control type="text" placeholder="Token Identifier" value={token} onChange={(e) => setToken(e.target.value)} />
                    </Form.Group>
                    <Form.Switch label="Is directly available" checked={directlyAvailable} onChange={(e) => { return setDirectlyAvailable(e.target.checked) }} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose(false)}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onSave()}>
                    <i className="bi bi-save"></i> Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  