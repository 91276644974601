import { Col, Container, Row } from "react-bootstrap";
import { EventReward } from "../logic/event/eventReward";
import { existsInArray } from "../logic/helper";
import { RewardCard } from "./RewardCard";

export function RewardContainer({ lastChangeId, rewards, collectedRewards, availablePoints = 0 }) {
    if (!(rewards instanceof Array) || !(collectedRewards instanceof Array)) {
        return <></>;
    }
    const rewardCards = rewards.map((reward) => {
        if (!(reward instanceof EventReward)) {
            return <></>;
        }
        const wasCollected = existsInArray(collectedRewards, reward);
        const canBeCollected = availablePoints > reward.getTargetPoints();
        return <Row key={reward.getId()}><Col ><RewardCard lastChangeId={lastChangeId} reward={reward} wasCollected={wasCollected} canBeCollected={canBeCollected} /></Col></Row>
    });
    return (
        <Container>
            <Row>
                <Col>Collect enough points doing tasks or finding tokens to unlock any of these rewards.</Col>
            </Row>

            {rewardCards}

        </Container>
    );
}