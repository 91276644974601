import { Nav, NavDropdown } from "react-bootstrap";

export function EventActionBar({ onEditClick, onResetClick, onImportClick, onExportClick, onExportIdClick, onQRCodeExport, onUploadClick, onDownloadClick, onDownloadImageClick, onCloudDeleteClick }) {
    return (
        <Nav variant="pills" activeKey="1">
            <Nav.Item className="me-2">
                <Nav.Link eventKey="1" onClick={() => onEditClick()}>
                    <i className="bi bi-pencil-square"></i> Details
                </Nav.Link>
            </Nav.Item>

            <NavDropdown title="Import">
                <NavDropdown.Item onClick={() => onImportClick()}>
                    <i className="bi bi-box-arrow-in-up"></i> Import
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onDownloadClick()}>
                    <i className="bi bi-cloud-download"></i> Download
                </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Export">
                <NavDropdown.Item onClick={() => onQRCodeExport()}>
                    <i className="bi bi-qr-code"></i> QR-Code
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onExportIdClick()}>
                    <i className="bi bi-key-fill"></i> ID
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onExportClick()}>
                    <i className="bi bi-box-arrow-down"></i> Clipboard
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onUploadClick()}>
                    <i className="bi bi-cloud-upload"></i> Upload
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => onDownloadImageClick()}>
                    <i className="bi bi-cloud-download-fill"></i> Images
                </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Other">
                <NavDropdown.Item onClick={() => onResetClick()}>
                    <i className="bi bi-x-octagon"></i> Reset event
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onCloudDeleteClick()}>
                    <i className="bi bi-cloud-minus"></i> Delete
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    )
}