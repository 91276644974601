import { Image } from "react-bootstrap";

export function Header() {
    return (
        <h1 className="fs-1">
            <a href="/">
                <Image src="/logo64.png" />
            </a> EventQuest
        </h1>
    );
}