import { Button, Stack } from "react-bootstrap";

export function RewardActionBar({ totalRewards, onCreateClick, onDeleteAllClick }) {
    let hideDeleteButton = "";
    if (totalRewards <= 0) {
        hideDeleteButton = "d-none";
    }
    return (
        <Stack gap={2} direction="horizontal" className="mb-3">
            <Button variant="primary" onClick={() => onCreateClick()}><i className="bi bi-plus-circle"></i> Add a reward</Button>
            <Button variant="danger" onClick={() => onDeleteAllClick()} className={hideDeleteButton}><i className="bi bi-trash"></i> Delete all rewards</Button>
        </Stack>
    )
}  