import { isInteger, isNull, isObject } from "../validation";
import { EventReward } from "../event/eventReward";
import { getNow } from "../helper";

export class ParticipantEventReward {
    constructor() {
        this.reward = null;
        this.collectedOn = 0;
    }
    getId() {
        return this.getReward().getId();
    }
    getReward() {
        if (isNull(this.reward)) {
            throw new ParticipantEventRewardError("No reward exists yet");
        }
        return this.reward;
    }
    wasCollected() {
        return this.collectedOn > 0;
    }
    getCollectedOn() {
        return this.collectedOn;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new ParticipantEventReward().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { reward: null, collectedOn: 0 }) {
        this.setReward(params.reward);
        this.setCollectedOn(params.collectedOn);
        return this;
    }
    setReward(reward) {
        if (!(reward instanceof EventReward)) {
            if (!isObject(reward)) {
                throw new ParticipantEventRewardError("Invalid parameter type: reward");
            }
            reward = new EventReward().applyExport(reward);
        }
        this.reward = reward;
        return this;
    }
    setCollectedOn(collectedOn) {
        if (!isInteger(collectedOn)) {
            throw new ParticipantEventRewardError("Invalid parameter type: collectedOn");
        }
        this.collectedOn = collectedOn;
        return this;
    }
    collected() {
        if (this.wasCollected()) {
            throw new ParticipantEventRewardError("Reward was already collected");
        }
        this.setCollectedOn(getNow());
        return this;
    }
}

export class ParticipantEventRewardError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventRewardError";
    }
}