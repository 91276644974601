import { Col, Container, Row, Form } from "react-bootstrap";
import { getFromArray } from "../logic/helper";
import { EventToken } from "../logic/event/eventToken";
import { TokenCard } from "./TokenCard";
import { useState } from "react";

export function TokenContainer({ isAvailable, lastChangeId, tokens, foundTokens, onTokenStart, onTokenCancel, onTokenManual }) {
    const [tokenInput, setTokenInput] = useState("");

    const validateToken = () => {
        onTokenManual(tokenInput);
        setTokenInput(""); // Reset input
    }

    if (!(tokens instanceof Array) || !(foundTokens instanceof Array)) {
        return <></>;
    }
    const tokenCards = tokens.filter((token) => {
        if (!(token instanceof EventToken)) {
            return false;
        }
        if (!token.isDirectlyAvailable()) {
            const participantToken = getFromArray(foundTokens, token);
            if (!participantToken) {
                return false;
            }
        }
        return true;
    }).sort((tokenA, tokenB) => {
        if (tokenA.getId() === lastChangeId) {
            return -1;
        } else if (tokenB.getId() === lastChangeId) {
            return 1;
        }

        const participantTokenA = getFromArray(foundTokens, tokenA);
        const participantTokenB = getFromArray(foundTokens, tokenB);

        // Order by not yet started tasks
        if (!participantTokenA && !participantTokenB) {
            return 0; // Equal and if neither is active yet there is nothing to compare
        }
        if (!participantTokenA) {
            return 1; // The second item is already active, the first is not. Therefore the not yet active itemA must be displayed first to hightlight it.
        }
        if (!participantTokenB) {
            return -1; // The first item is already active, the second ist not. Therefore the not yet active itemB must be displayed first to highlight it.
        }
        //At this point both are already active

        // Order by not yet completed tasks
        const statusA = participantTokenA.getStatus();
        const statusB = participantTokenB.getStatus();
        if (statusA.isActive() && !statusB.isActive()) {
            return -1; // The second item is no longer active but the first is. We want to promote tasks to be completed, therefore display itemA first.
        }
        if (!statusA.isActive() && statusB.isActive()) {
            return 1; // The first item is no longer active but the second is. We want to promote tasks to be completed, therefore display itemB first.
        }

        // At this point either both are active or both are completed.
        return statusB.getLatest() - statusA.getLatest();

    }).map((token) => {
        const participantToken = getFromArray(foundTokens, token);
        return (
            <Row key={token.getId()}>
                <Col>
                    <TokenCard isAvailable={isAvailable} lastChangeId={lastChangeId} token={token} participantToken={participantToken} onTokenStart={onTokenStart} onTokenCancel={onTokenCancel} />
                </Col>
            </Row>
        );
    });
    const generateTokenInput = () => {
        if (!isAvailable) {
            return <></>;
        }
        return (
            <Row>
                <Form className="pt-3" onSubmit={(e) => { e.preventDefault(); validateToken(); }}>
                    <Form.Group className="mb-3" controlId="tokenInput">
                        <Form.Control type="text" placeholder="Manually activate a token" autoComplete="false" autoFocus value={tokenInput} onChange={(e) => setTokenInput(e.target.value)} />
                    </Form.Group>
                </Form>
            </Row>
        )
    }
    return (
        <Container>
            <Row>
                <Col>Find and collect tokens to gain points and earn rewards.</Col>
            </Row>
            {generateTokenInput()}
            {tokenCards}
        </Container>
    );
}