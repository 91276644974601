import { ParticipantEvent } from "./participantEvent";
import { isArray, isInteger, isObject, isString } from "../validation";
import { existsInArray, filterFromArray, getFromArrayById, getNow } from "../helper";

export class Participant {
    #applyExport = false; // Can be used to ignore validations during clone / restoration

    constructor() {
        this.name = "";
        this.created = getNow();
        this.joinedEvents = []; //ParticipantEvent
    }

    getName() {
        return this.name;
    }
    getCreated() {
        return this.created;
    }
    getParticipantAge() {
        return getNow() - this.created;
    }
    getJoinedEvents() {
        return this.joinedEvents;
    }
    countJoinedEvents() {
        return this.joinedEvents.length;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new Participant().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { name: "", created: 0, joinedEvents: [] }) {
        this.#applyExport = true;

        this.setName(params.name);
        this.setCreated(params.created);
        this.setJoinedEvents(params.joinedEvents);

        this.#applyExport = false;
        return this;
    }
    setName(name = "") {
        if (!isString(name)) {
            throw new ParticipantError("Invalid parameter type: name");
        }
        this.name = name;
        return this;
    }
    setCreated(created = 0) {
        if (!isInteger(created)) {
            throw new ParticipantError("Invalid parameter type: created");
        }
        this.created = created;
        return this;
    }
    setJoinedEvents(event) {
        if (!isArray(event)) {
            throw new ParticipantError("Invalid parameter type: joinedEvents");
        }
        this.joinedEvents = [];
        for (let i = 0; i < event.length; i++) {
            this.addJoinedEvent(event[i]);
        }
        return this;
    }
    knownJoinedEvent(event) {
        return existsInArray(this.joinedEvents, event);
    }
    addJoinedEvent(event) {
        if (!(event instanceof ParticipantEvent)) {
            if (!isObject(event)) {
                throw new ParticipantError("Invalid parameter type: joinedEvents[item]");
            }
            event = new ParticipantEvent().applyExport(event);
        }
        if (this.knownJoinedEvent(event)) {
            throw new ParticipantError("Event already known");
        }
        if (!this.#applyExport && !event.isActive()) {
            throw new ParticipantError("Event is not active");
        }
        this.joinedEvents = [...this.joinedEvents, event];
        return this;
    }
    removeJoinedEventById(joinedEventId) {
        this.joinedEvents = filterFromArray(this.joinedEvents, joinedEventId);
        return this;
    }
    getJoinedEventById(joinedEventId) {
        return getFromArrayById(this.joinedEvents, joinedEventId);
    }
}

export class ParticipantError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantError";
    }
}