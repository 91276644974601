import { Stack } from "react-bootstrap";
import { FullPageContainer } from "../components/FullPageContainer";
import { getParticipantData } from "../logic/database";
import { useLoaderData } from "react-router-dom";
import { Participant } from "../logic/participant/participant";
import { ParticipantEvent } from "../logic/participant/participantEvent";
import { EventCard } from "../components/EventCard";
import { ErrorPage } from "../ErrorPage";

export async function Loader({ params }) {
    try {
        const participantObj = await getParticipantData();
        return { participant: participantObj };
    } catch (error) {
        return { participant: undefined, errorContext: error };
    }
}

export function Homepage() {
    const { participant, errorContext } = useLoaderData();

    if (errorContext) {
        return (<ErrorPage customError={errorContext} description="Oops, looks like there was an error while loading data required for this page to work correctly." />);
    }

    const eventCards = () => {
        if (!participant || !(participant instanceof Participant)) { return <></>; }

        const cardHtml = participant.getJoinedEvents()
            .sort((event1, event2) => {
                return event1.getJoined() - event2.getJoined();
            })
            .map((eventDetails) => {
                if (!(eventDetails instanceof ParticipantEvent)) { return <></>; }
                return (<EventCard key={eventDetails.getId()} eventData={eventDetails.getEvent()} eventJoined={true} />);
            });

        return cardHtml;
    }

    const notJoinedAnyEventYet = () => {
        if (!participant || !(participant instanceof Participant)) { return <></>; }
        if (participant.getJoinedEvents().length > 0) { return <></>; }

        return (
            <div className="fs-5 text-wrap">Looks like you didn't join any event yet. Come back here once you found an event in the wild!</div>
        );
    }


    return (
        <FullPageContainer>
            <div>
                {notJoinedAnyEventYet()}
                <Stack>
                    {eventCards()}
                </Stack>
            </div>
        </FullPageContainer>
    )
}