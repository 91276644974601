import { v4 as uuidv4 } from 'uuid';
import { hasFunction } from './validation';
import { DateTime, Duration } from 'luxon';

export function getUUID() {
    return uuidv4();
}
export function getNow() {
    return DateTime.local().toMillis();
}
export function getLocalDateOnly(dateInt) {
    return DateTime.fromMillis(dateInt).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis()
}
export function getTomorrow(dateInt) {
    return DateTime.fromMillis(dateInt).plus({ day: 1 }).toMillis();
}
export function getDurationText(durationInt) {
    const currentDuration = Duration.fromMillis(durationInt).rescale().toObject();
    delete currentDuration.milliseconds;
    delete currentDuration.seconds;
    return Duration.fromObject(currentDuration, { locale: 'en-US' }).toHuman();
}
export function existsInArray(eventIdObjArray, eventIdObj) {
    return !!(getFromArray(eventIdObjArray, eventIdObj));
}
export function getFromArray(eventIdObjArray, eventIdObj) {
    return eventIdObjArray.find((obj) => {
        if (!hasFunction(obj, "getId")) {
            return false;
        }
        return eventIdObj?.getId() === obj?.getId();
    });
}
export function getFromArrayById(eventIdObjArray, objectId) {
    return eventIdObjArray.find((obj) => {
        if (!hasFunction(obj, "getId")) {
            return false;
        }
        return objectId === obj?.getId();
    });
}
export function filterFromArray(eventIdObjArray, objectId) {
    return eventIdObjArray.filter((obj) => {
        if (!hasFunction(obj, "getId")) {
            return false;
        }
        return objectId !== obj?.getId();
    });
}
export function updateInArray(eventIdObjArray, eventIdObj) {
    return eventIdObjArray.map((obj) => {
        if (!hasFunction(obj, "getId")) {
            return obj;
        }
        if (eventIdObj?.getId() === obj?.getId()) {
            return eventIdObj;
        }
        return obj;
    });
}
export function convertDateFromInput(dateAsString) {
    return new Date(dateAsString).getTime();
}
export function convertDateForOutput(dateAsInt) {
    try {
        return DateTime.fromMillis(dateAsInt).toFormat("yyyy-MM-dd'T'HH:mm");
    } catch (error) {
        return DateTime.fromMillis(getNow()).toFormat("yyyy-MM-dd'T'HH:mm");
    }
}
export function formatTimestamp(timestamp) {
    return new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'medium',
        timeStyle: 'short',
    }).format(new Date(timestamp));
}
export function pad(input, paddingLength = 2, paddingCharacter = "0") {
    return input.toString().padStart(paddingLength, paddingCharacter);
}
export function getFileContent(fileObj) {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
        reader.readAsDataURL(fileObj);
        reader.onloadend = function () {
            resolve(reader.result);
        }
        reader.onerror = function () {
            reject();
        }
        reader.onabort = function () {
            reject();
        }
    });
}
export const optimizationQuality = {
    "low": { width: 600, height: 400, filemime: 'image/jpeg', quality: 0.75 },
    "high": { width: 1000, height: 1200, filemime: 'image/jpeg', quality: 0.90 },
    "perfect": { width: 2000, height: 2000, filemime: 'image/jpeg', quality: 0.95 },
}
export function optimizeImage(imageData, targetQualityIdentifier = "low") {
    //https://zocada.com/compress-resize-images-javascript-browser/
    const target = optimizationQuality[targetQualityIdentifier];
    return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = imageData;
        img.onload = () => {
            const elem = document.createElement('canvas');
            const ctx = elem.getContext('2d');
            let height = 0, width = 0;

            if (img.height > img.width && img.height > target.height) {
                //Scale down based on height
                height = target.height;
                width = img.width * (target.height / img.height);
            } else if (img.width > img.height && img.width > target.width) {
                //Scale down based on width
                width = target.width;
                height = img.height * (target.width / img.width);
            } else {
                //Keep current dimensions
                width = img.width;
                height = img.height;
            }

            elem.width = width;
            elem.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            ctx.canvas.toBlob(() => {
                const imageDataUrl = ctx.canvas.toDataURL(target.filemime, target.quality);
                resolve(imageDataUrl);
            }, target.filemime, target.quality);
        }
        img.onerror = () => {
            reject();
        }
        img.onabort = () => {
            reject();
        }
    });
}
export async function imageSelectionProcesser(selectedFilePath, targetQualityIdentifier = "low") {
    const fileContent = await getFileContent(selectedFilePath);
    const optimizedImage = await optimizeImage(fileContent, targetQualityIdentifier);
    return optimizedImage;
}
export function setClipboardText(text) {
    return new Promise((resolve, reject) => {
        navigator.clipboard.writeText(text).then(function () {
            resolve();
        }, function (err) {
            reject(err);
        });
    });
}
export function getClipboardText() {
    return new Promise((resolve, reject) => {
        navigator.clipboard.readText().then(function (text) {
            resolve(text);
        }, function (err) {
            reject(err);
        });
    });
}
export function getLocation() {
    return window.location.origin;
}
export function getRelativeUrl(url) {
    return url.replace(getLocation(), "");
}
export function isCameraAvailable() {
    return new Promise((resolve) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            resolve(devices.some(device => device.kind === 'videoinput'))
        }).catch((error) => {
            resolve(false);
        });
    });
}
export function isAndroid() {
    return !!navigator.userAgent.match(/Android/i);
}
export function getRelativeLoction() {
    return window.location.pathname;
}
/*
export function getRelativeRootPath(params = { path: undefined, pops: 1 }) {
    if (!params.path) { params.path = getRelativeLoction() }
    const path = addTailingSlash(params.path); // Make sure the last part of the path is closed of.
    const pathSegments = path.split('/');
    for (let i = 0; i < params.pops; i++) {
        pathSegments.pop(); // Remove last segment
    }
    return addTailingSlash(pathSegments.join('/'));
}
*/
export function addTailingSlash(path) {
    path += path.endsWith("/") ? "" : "/";
    return path;
}
export function getEventIdFromUrl() {
    const reg = /\/event\/(.*?)(?:\/|$)/gm;
    const result = reg.exec(getRelativeLoction())
    return result[1];
}

export function getWelcomeUrl() {
    return "/u/welcome";
}
export function getEventBaseUrl(eventId) {
    return `/p/event/${eventId}`;
}
export function getEventViewUrl(eventId) {
    return `${getEventBaseUrl(eventId)}/view`;
}
export function getEventStartUrl(eventId, activityId) {
    return `${getEventBaseUrl(eventId)}/start/${activityId}`;
}
export function getEventCompleteUrl(eventId, activityId, secret) {
    return `${getEventBaseUrl(eventId)}/complete/${activityId}/${secret}`;
}
export function getEventConfirmRewardUrl(eventId, rewardId, secret) {
    return `${getEventBaseUrl(eventId)}/confirm/${rewardId}/${secret}`;
}
export function getEventFindUrl(eventId, activityId) {
    return `${getEventBaseUrl(eventId)}/find/${activityId}`;
}
export function getEventCollectUrl(eventId, activityId, secret) {
    return `${getEventBaseUrl(eventId)}/collect/${activityId}/${secret}`;
}
