import { getUUID } from "../helper";
import { isString } from "../validation";

export class EventId {
    constructor() {
        this.id = getUUID();
    }
    getId() {
        return this.id;
    }
    setId(id = "") {
        if (!isString(id)) {
            throw new EventIdError("Invalid parameter type: id");
        }
        if (id.length <= 0) {
            throw new EventIdError("Provided id is empty");
        }
        /*
        if (this.id.length > 0) {
            throw new EventIdError("ID is already set and can be overwritten");
        }
        */
        this.id = id;
        return this;
    }
}

export class EventIdError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventIdError";
    }
}

/*
import { getUUID } from "../helper";
import { isString } from "../validation";

export class EventId {
    #id = "";

    get id(){
        if (this.#id.length === 0) {
            this.#id = getUUID();
        }
        return this.#id;
    }
    getId() {
        return this.id;
    }
    setId(id = "") {
        if (!isString(id)) {
            throw new EventIdError("Invalid parameter type: id");
        }
        if (id.length < 0) {
            throw new EventIdError("Provided id is empty");
        }
        if (this.#id.length > 0) {
            throw new EventIdError("ID is already set and can be overwritten");
        }
        this.#id = id;
    }
}

export class EventIdError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventIdError";
    }
}
    */