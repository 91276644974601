import { Navigate, Outlet, useLoaderData } from "react-router-dom";
import { participantDataExists } from "../logic/database";
import { getWelcomeUrl } from "../logic/helper";

export async function Loader() {
    try {
        const isRegistered = await participantDataExists();
        return { isRegistered }
    } catch (error) {
        return { isRegistered: false }
    }
}

export function RequireRegistration({ mustBeRegistered = true, target = getWelcomeUrl() }) {
    const { isRegistered } = useLoaderData();
    return isRegistered === mustBeRegistered ? <Outlet /> : <Navigate to={target} />;
}