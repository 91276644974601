import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export function RewardDetailModal({ rewardState, onClose }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [targetPoints, setTargetPoints] = useState(0);

    const onOpen = () => {
        setTitle(rewardState.getTitle());
        setDescription(rewardState.getDescription());
        setTargetPoints(rewardState.getTargetPoints());
    }

    const onSave = () => {
        rewardState.setTitle(title);
        rewardState.setDescription(description);
        rewardState.setTargetPoints(targetPoints);
        onClose(true, rewardState);
    }

    return (
        <Modal show={!!(rewardState)} backdrop="static" size="lg" fullscreen="xl-down" onHide={() => onClose(false)} onShow={() => onOpen()}>
            <Modal.Header closeButton>
                <Modal.Title>Reward Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Reward title" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Reward description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="points">
                        <Form.Label>Target points</Form.Label>
                        <Form.Control type="number" placeholder="Reward target points" value={targetPoints} min={1} onChange={(e) => setTargetPoints(Number.parseInt(e.target.value))} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose(false)}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onSave()}>
                    <i className="bi bi-save"></i> Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  