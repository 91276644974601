import { Badge, Button, Card, Stack } from "react-bootstrap";
import { EventToken } from "../../logic/event/eventToken"

export function TokenCard({ token, onDeleteClick, onEditClick }) {
    if (!(token instanceof EventToken)) {
        return (<></>);
    }
    const directlyAvailableBadge = token.isDirectlyAvailable() ? <Badge bg="primary">Is directly available</Badge> : <></>;

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    {token.getTitle()}
                </Card.Title>
                <Card.Text>
                    {token.getDescription()}
                </Card.Text>
                <Card.Text className="fst-italic">
                    {token.getHint()}
                </Card.Text>
                <Stack direction="horizontal" gap={2}>
                    <Button variant="primary" onClick={() => onEditClick(token)}><i className="bi bi-pencil-square"></i> Edit</Button>
                    <Button variant="danger" onClick={() => onDeleteClick(token.getId())}><i className="bi bi-trash"></i> Delete</Button>
                </Stack>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">Points: {token.getPoints()}</small> {directlyAvailableBadge}
            </Card.Footer>
        </Card>
    )
}  