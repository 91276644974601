import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export function AccessCodeModal({ visible, onClose }) {
    const [accessCode, setAccessCode] = useState("");

    return (
        <Modal show={visible} fullscreen="xl-down" onHide={() => onClose("")} onSubmit={(e) => { e.preventDefault(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Set Access Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="secret">
                        <Form.Label>Access Code</Form.Label>
                        <Form.Control type="password" placeholder="Editor access code" autoFocus value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose("")}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onClose(accessCode)}>
                    <i className="bi bi-check-lg"></i> Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  