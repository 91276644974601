import { useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getEventBaseUrl, getEventViewUrl } from "../logic/helper";
import { Spinner } from "react-bootstrap";
import { FullPageContainer } from "./FullPageContainer";
import { registerNotification } from "../logic/database";

export async function Loader({ params }) {
    return { params };
}

export function ActionRedirect({ redirectTarget = "", action }) {
    const navigate = useNavigate();
    const { params } = useLoaderData();

    let targetUrl = "/";
    if (redirectTarget === "eventView") {
        targetUrl = getEventViewUrl(params.eventId);
    } else if (redirectTarget === "eventPreview") {
        targetUrl = getEventBaseUrl(params.eventId);
    }
    // for each target the notifications shall be checked!!
    // alternative dynamic url: targetUrl += "?updated=" + Date.now()

    useEffect(() => {
        if (!!action) {
            action({ params: params }).then(() => {
                navigate(targetUrl, { replace: true }); // This is the refresh after all activies are completed
            }).catch((e) => {
                registerNotification({
                    id: "action-error",
                    variant: "danger",
                    title: "Action Failed",
                    text: e.statusText || e.message || "Unknown Error",
                    delay: 10000
                }).catch(() => {
                    // probably double render problem, but doesn´t matter the notification was already registered
                }).finally(() => {
                    navigate(targetUrl, { replace: true });
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FullPageContainer>
            <div>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </FullPageContainer>
    );
}