import { Toast } from "react-bootstrap";

export function CustomToast({ visible, title, text, onClose, variant = "primary", position = "top-end", delay = 3000 }) {
    return (
        <Toast show={visible} onClose={() => onClose()} position={position} bg={variant} delay={delay} autohide={delay > 0}>
            <Toast.Header>
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body className={variant === 'light' ? "" : "text-white"}>
                {text}
            </Toast.Body>
        </Toast>
    );
}