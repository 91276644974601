import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { EventTask } from "../../logic/event/eventTask";

export function TaskDetailModal({ taskState, onClose }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [points, setPoints] = useState(0);
    const [directlyAvailable, setDirectlyAvailable] = useState(false);
    const [expectImage, setExpectImage] = useState(false);

    const onOpen = () => {
        setTitle(taskState.getTitle());
        setDescription(taskState.getDescription());
        setPoints(taskState.getPoints());
        setDirectlyAvailable(taskState.isDirectlyAvailable());
        setExpectImage(taskState.doesExpectImage());
    }

    const onSave = () => {
        taskState.setTitle(title);
        taskState.setDescription(description);
        taskState.setPoints(points);
        taskState.setDirectlyAvailable(directlyAvailable);
        taskState.setExpectImage(expectImage);
        onClose(true, taskState);
    }

    return (
        <Modal show={!!(taskState)} backdrop="static" size="lg" fullscreen="xl-down" onHide={() => onClose(false)} onShow={() => onOpen()}>
            <Modal.Header closeButton>
                <Modal.Title>Task Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Task title" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Task description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="points">
                        <Form.Label>Points</Form.Label>
                        <Form.Control type="number" placeholder="Task points" value={points} min={EventTask.getMinPoints()} max={EventTask.getMaxPoints()} onChange={(e) => setPoints(Number.parseInt(e.target.value))} />
                    </Form.Group>
                    <Form.Switch label="Is directly available" checked={directlyAvailable} onChange={(e) => { return setDirectlyAvailable(e.target.checked) }} />
                    <Form.Switch label="Expects image" checked={expectImage} onChange={(e) => { return setExpectImage(e.target.checked) }} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose(false)}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onSave()}>
                    <i className="bi bi-save"></i> Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  