import { getUUID } from "./helper";
import { isArray, isBoolean, isInteger, isString } from "./validation";

export class Notification {
    constructor() {
        this.id = getUUID();
        this.variant = "primary";
        this.title = "";
        this.text = "";
        this.delay = 0;
        this.position = "top-end";
        this.display = true;
        this.data = [];
    }

    getId() {
        return this.id;
    }
    getVariant() {
        return this.variant;
    }
    getTitle() {
        return this.title;
    }
    getText() {
        return this.text;
    }
    getDelay() {
        return this.delay;
    }
    getPosition() {
        return this.position;
    }
    getData() {
        return this.data;
    }
    getDisplay() {
        return this.display;
    }

    applyExport(params = { id: "", variant: "primary", title: "", text: "", delay: 0, position: "top-end", display: true, data: [] }) {
        this.setId(params.id);
        this.setVariant(params.variant);
        this.setTitle(params.title);
        this.setText(params.text);
        this.setDelay(params.delay);
        this.setPosition(params.position);
        this.setDisplay(params.display);
        this.setData(params.data);
        return this;
    }

    setId(id = "") {
        if (!isString(id)) {
            throw new NotificationError("Invalid parameter type: id");
        }
        this.id = id;
        return this;
    }
    setVariant(variant = "") {
        if (!isString(variant)) {
            throw new NotificationError("Invalid parameter type: variant");
        }
        this.variant = variant;
        return this;
    }
    setTitle(title = "") {
        if (!isString(title)) {
            throw new NotificationError("Invalid parameter type: title");
        }
        this.title = title;
        return this;
    }
    setText(text = "") {
        if (!isString(text)) {
            throw new NotificationError("Invalid parameter type: text");
        }
        this.text = text;
        return this;
    }
    setDelay(delay = 0) {
        if (!isInteger(delay)) {
            throw new NotificationError("Invalid parameter type: points");
        }
        this.delay = delay;
        return this;
    }
    setPosition(position = "") {
        if (!isString(position)) {
            throw new NotificationError("Invalid parameter type: position");
        }
        this.position = position;
        return this;
    }
    setDisplay(display = true) {
        if (!isBoolean(display)) {
            throw new NotificationError("Invalid parameter type: display");
        }
        this.display = display;
        return this;
    }
    setData(data = []) {
        if (!isArray(data)) {
            throw new NotificationError("Invalid parameter type: data");
        }
        this.data = data;
        return this;
    }
}

export class NotificationError extends Error {
    constructor(message) {
        super(message);
        this.name = "NotificationError";
    }
}