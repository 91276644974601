import { getNow } from "../helper";
import { isInteger } from "../validation";

export class ParticipantEventStatus {
    constructor() {
        this.started = getNow();
        this.finished = 0;
        this.canceled = 0;
    }

    isFinished() {
        return this.finished > 0;
    }
    isCanceled() {
        return this.canceled > 0;
    }
    isActive() {
        return !this.isFinished() && !this.isCanceled();
    }
    getStarted() {
        return this.started;
    }
    getLatest() {
        if (this.finished > this.started) {
            return this.finished;
        }
        if (this.canceled > this.started) {
            return this.canceled;
        }
        return this.started;
    }
    finish() {
        if (!this.isActive()) {
            throw new ParticipantEventStatusError("Activity is already completed and can therefore not switch to finished.");
        }
        this._setFinished(getNow());
        return this;
    }
    cancel() {
        if (!this.isActive()) {
            throw new ParticipantEventStatusError("Activity is already completed and can therefore not switch to canceled.");
        }
        this._setCanceled(getNow());
        return this;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new ParticipantEventStatus().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { started: 0, finished: 0, canceled: 0 }) {
        if (params.finished && params.canceled) {
            throw new ParticipantEventStatusError("Activity can not be canceled and finished at the same time.");
        }

        this._setStarted(params.started);
        this._setFinished(params.finished);
        this._setCanceled(params.canceled);
        return this;
    }
    _setStarted(started = 0) {
        if (!isInteger(started)) {
            throw new ParticipantEventStatusError("Invalid parameter type: started");
        }
        this.started = started;
        return this;
    }
    _setFinished(finished = 0) {
        if (!isInteger(finished)) {
            throw new ParticipantEventStatusError("Invalid parameter type: finished");
        }
        this.finished = finished;
        return this;
    }
    _setCanceled(canceled = 0) {
        if (!isInteger(canceled)) {
            throw new ParticipantEventStatusError("Invalid parameter type: canceled");
        }
        this.canceled = canceled;
        return this;
    }
}

export class ParticipantEventStatusError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventStatusError";
    }
}