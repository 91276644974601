import { EventDefinition } from "../event/eventDefinition";
import { ParticipantEventTask } from "./participantEventTask";
import { ParticipantEventToken } from "./participantEventToken";
import { isArray, isInteger, isNull, isObject } from "../validation";
import { existsInArray, getFromArrayById, getNow } from "../helper";
import { ParticipantEventReward } from "./participantEventReward";

export class ParticipantEvent {
    #applyExport = false; // Can be used to ignore validations during clone / restoration

    constructor() {
        this.event = null; //EventDefinition
        this.tasks = []; //ParticipantEventTask
        this.tokens = []; //ParticipantEventToken
        this.rewards = []; //ParticipantEventRewards
        this.joined = getNow();
    }
    getId() {
        return this.getEvent().getId();
    }
    canBeUsed() {
        return !isNull(this.event);
    }
    getEvent() {
        if (!this.canBeUsed()) {
            throw new ParticipantEventError("No event exists yet");
        }
        return this.event;
    }
    getTasks() {
        return this.tasks;
    }
    getTokens() {
        return this.tokens;
    }
    getRewards() {
        return this.rewards;
    }
    getJoined() {
        return this.joined;
    }
    getAge() {
        return getNow() - this.joined;
    }
    isActive() {
        return this.getEvent().isActive();
    }
    getSumCollectedRewardPoints() {
        return this.getRewards().reduce((accumulator, reward) => {
            return accumulator + (reward.wasCollected() ? reward.getReward().getTargetPoints() : 0);
        }, 0);
    }
    customActivityPointReducer(accumulator, activity) {
        return accumulator + (activity.getStatus().isFinished() ? activity.getActivity().getPoints() : 0);
    }
    getSumTaskPoints() {
        return this.getTasks().reduce(this.customActivityPointReducer, 0);
    }
    getSumTokenPoints() {
        return this.getTokens().reduce(this.customActivityPointReducer, 0);
    }
    getSumCollectedPoints() {
        return this.getSumTaskPoints() + this.getSumTokenPoints();
    }

    customActivityStatusUpdateReducer(accumulator, referenceObj) {
        const status = referenceObj.getStatus();
        if (!status) {
            return accumulator;
        }
        if (status.getLatest() > accumulator.value) {
            return { value: status.getLatest(), object: referenceObj };
        }
        return accumulator;
    }
    getLastChangedTask() {
        return this.getTasks().reduce(this.customActivityStatusUpdateReducer, { value: 0, object: undefined });
    }
    getLastChangedToken() {
        return this.getTokens().reduce(this.customActivityStatusUpdateReducer, { value: 0, object: undefined });
    }
    getLastChangedReward() {
        return this.getRewards().reduce((accumulator, referenceObj) => {
            if (!referenceObj.wasCollected()) {
                return accumulator;
            }
            if (referenceObj.getCollectedOn() > accumulator.value) {
                return { value: referenceObj.getCollectedOn(), object: referenceObj };
            }
            return accumulator;
        }, { value: 0, object: undefined });
    }
    getLastChangeType() {
        const accumulatedData = [
            { key: "tasks", reference: this.getLastChangedTask() },
            { key: "tokens", reference: this.getLastChangedToken() },
            { key: "rewards", reference: this.getLastChangedReward() },
        ];
        return accumulatedData.sort((a, b) => { return b.reference.value - a.reference.value })[0];
    }
    getAvailablePoints() {
        return this.getSumCollectedPoints() - this.getSumCollectedRewardPoints();
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new ParticipantEvent().applyExport(JSON.parse(this.getExport()));
    }

    applyExport(params = { event: null, tasks: [], tokens: [], rewards: [], joined: 0 }) {
        this.#applyExport = true;

        this.setEvent(params.event);
        this.setTasks(params.tasks);
        this.setTokens(params.tokens);
        this.setRewards(params.rewards);
        this.setJoined(params.joined);

        this.#applyExport = false;
        return this;
    }
    setEvent(event) {
        if (!(event instanceof EventDefinition)) {
            if (!isObject(event)) {
                throw new ParticipantEventError("Invalid parameter type: event");
            }
            event = new EventDefinition().applyExport(event);
        }
        this.event = event;
        return this;
    }
    setJoined(joined) {
        if (!isInteger(joined)) {
            throw new ParticipantEventError("Invalid parameter type: joined");
        }
        this.joined = joined;
        return this;
    }
    getTokenById(tokenId) {
        return getFromArrayById(this.tokens, tokenId);
    }
    getTokenByText(tokenText) {
        return this.getEvent().getTokenByText(tokenText);
    }
    setTokens(tokens) {
        if (!isArray(tokens)) {
            throw new ParticipantEventError("Invalid parameter type: tokens");
        }
        this.tokens = [];
        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
            this.addToken(token);
        }
        return this;
    }
    knownToken(participantToken) {
        return existsInArray(this.tokens, participantToken);
    }
    isEventToken(participantToken) {
        return existsInArray(this.getEvent().getTokens(), participantToken.getToken());
    }
    addToken(token) {
        if (!(token instanceof ParticipantEventToken)) {
            if (!isObject(token)) {
                throw new ParticipantEventError("Invalid parameter type: tokens[item]");
            }
            token = new ParticipantEventToken().applyExport(token);
        }
        if (this.knownToken(token)) {
            throw new ParticipantEventError("Token already known");
        }
        if (!this.#applyExport && !this.isEventToken(token)) {
            throw new ParticipantEventError("Token is not part of this event.");
        }
        if (!this.#applyExport && !this.isActive()) {
            throw new ParticipantEventError("Event is not active.");
        }

        this.tokens = [...this.tokens, token];
        return this;
    }
    getTaskById(taskId) {
        return getFromArrayById(this.tasks, taskId);
    }
    setTasks(tasks) {
        if (!isArray(tasks)) {
            throw new ParticipantEventError("Invalid parameter type: tasks");
        }
        this.tasks = [];
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            this.addTask(task);
        }
        return this;
    }
    knownTask(task) {
        return existsInArray(this.tasks, task);
    }
    isEventTask(participantTask) {
        return existsInArray(this.getEvent().getTasks(), participantTask.getTask());
    }
    addTask(task) {
        if (!(task instanceof ParticipantEventTask)) {
            if (!isObject(task)) {
                throw new ParticipantEventError("Invalid parameter type: tasks[item]");
            }
            task = new ParticipantEventTask().applyExport(task);
        }
        if (this.knownTask(task)) {
            throw new ParticipantEventError("Task already known");
        }
        if (!this.#applyExport && !this.isEventTask(task)) {
            throw new ParticipantEventError("Task is not part of this event.");
        }
        if (!this.#applyExport && !this.isActive()) {
            throw new ParticipantEventError("Event is not active.");
        }

        this.tasks = [...this.tasks, task];
        return this;
    }
    getRewardById(rewardId) {
        return getFromArrayById(this.rewards, rewardId);
    }
    setRewards(rewards) {
        if (!isArray(rewards)) {
            throw new ParticipantEventError("Invalid parameter type: rewards");
        }
        this.rewards = [];
        for (let i = 0; i < rewards.length; i++) {
            const reward = rewards[i];
            this.addReward(reward);
        }
        return this;
    }
    knownReward(reward) {
        return existsInArray(this.rewards, reward);
    }
    isEventReward(participantReward) {
        return existsInArray(this.getEvent().getRewards(), participantReward.getReward());
    }
    addReward(reward) {
        if (!(reward instanceof ParticipantEventReward)) {
            if (!isObject(reward)) {
                throw new ParticipantEventError("Invalid parameter type: rewards[item]");
            }
            reward = new ParticipantEventReward().applyExport(reward);
        }
        if (this.knownReward(reward)) {
            throw new ParticipantEventError("Reward already collected");
        }
        if (!this.#applyExport && !this.isEventReward(reward)) {
            throw new ParticipantEventError("Reward is not part of this event.");
        }
        if (!this.#applyExport && !this.isActive()) {
            throw new ParticipantEventError("Event is not active.");
        }
        if (!this.#applyExport && this.getAvailablePoints() < reward.getReward().getTargetPoints()) {
            throw new ParticipantEventError("Not enough points collected.");
        }

        this.rewards = [...this.rewards, reward];
        return this;
    }

}
export class ParticipantEventError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventError";
    }
}