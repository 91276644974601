import { useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { uploadImage } from "../logic/web";
import { getParticipantData, registerNotification } from "../logic/database";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { getEventViewUrl } from "../logic/helper";

export const IDENTIFIER = "image-upload";

export function ImageUploadConfirmation({ data, onClose }) {
    const navigate = useNavigate();
    const [confirmationModalIsVisible, setConfirmationModalIsVisible] = useState(true);
    const [, eventId, taskId] = data;

    const handleClose = async (confirmed) => {
        if (confirmed) {
            try {
                const participantObj = await getParticipantData();
                const participantEvent = participantObj.getJoinedEventById(eventId);
                const participanTask = participantEvent.getTaskById(taskId);
                await uploadImage(participantEvent.getEvent(), participanTask.getImage());
                await registerNotification({
                    id: `${taskId}-upload-confirmation`,
                    title: "Image was uploaded",
                    text: "Looks good, your taks image was uploaded.",
                    delay: 5000,
                });
            } catch (error) {
                await registerNotification({
                    id: `${taskId}-upload-failed-user`,
                    variant: "danger",
                    title: "Image upload failed",
                    text: "Yikes, the upload of the image failed.",
                    delay: 10000,
                });
            }
        }
        setConfirmationModalIsVisible(false);
        onClose();
        navigate(getEventViewUrl(eventId)); // Trigger reload for notifications
    }

    return (<ConfirmationModal title="Upload Image" visible={confirmationModalIsVisible} onClose={handleClose} text={
        <Container>
            <Row>
                <Col>
                    Would you like to upload the task image and share it with the event organizers?
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className="text-muted">When you upload an image, you are confirming that you have the permission of everyone in the image to share it and that it is not copyrighted material.</small>
                </Col>
            </Row>
        </Container>} />)
}