import { Badge, Button, Card } from "react-bootstrap"

export function TokenCard({ isAvailable, lastChangeId, token, participantToken, onTokenStart, onTokenCancel }) {
    const isFinished = (!!participantToken && participantToken?.getStatus()?.isFinished());
    const cardBorder = isFinished ? "border border-primary" : "";

    const getFooter = () => {
        if (!participantToken) {
            return <Badge bg="secondary" aria-description="Not found">Find to earn {token.getPoints()} points</Badge>;
        }
        if (participantToken.getStatus().isCanceled()) {
            return <Badge bg="warning" aria-description="Canceled"><i className="bi bi-ban"></i> Given up</Badge>;
        }
        if (participantToken.getStatus().isFinished()) {
            return <Badge bg="primary" aria-description="Completed"><i className="bi bi-award-fill"></i> Found</Badge>;
        }
        if (participantToken.getStatus().isActive()) {
            return <Badge bg="secondary" aria-description="Can collect"><i className="bi bi-award"></i> Find token and earn {token.getPoints()} points</Badge>;
        }
        return <Badge bg="secondary" aria-description="Not available">Unknown</Badge>;
    }
    const getNewBadge = () => {
        if (lastChangeId !== token.getId()) {
            return <></>;
        }
        return <Badge bg="primary" aria-description="New"><i className="bi bi-stars"></i> NEW</Badge>;
    }
    const getButtons = () => {
        const buttons = [];
        // If the token is directly available and the token was not yet startet show the start button
        if (isAvailable && !participantToken) {
            buttons.push(<Button key="startTokenButton" variant="outline-primary" size="sm" onClick={() => { onTokenStart(token.getId()); }}> <i className="bi bi-activity"></i> Search token</Button >);
        }
        if (isAvailable && !!participantToken && participantToken.getStatus().isActive()) {
            // Currently disabled. I don't really see why anybody would have a use for this.
            // buttons.push(<Button key="cancelTokenButton" variant="outline-warning" size="sm" onClick={() => { onTokenCancel(token.getId()); }}><i className="bi bi-x-lg"></i> Give up on token</Button>);
        }

        if (buttons.length <= 0) {
            return <></>;
        }
        return <Card.Body className="border-top">{buttons.flat()}</Card.Body>;
    }

    const getTokenBody = () => {
        if (isFinished) {
            return (
                <Card.Body>
                    <Card.Title as="h4">
                        {token.getTitle()}
                    </Card.Title>
                    <Card.Text>
                        {token.getDescription()}
                    </Card.Text>
                </Card.Body>
            );
        }
        return (
            <Card.Body>
                <Card.Text>
                    {token.getHint()}
                </Card.Text>
            </Card.Body>
        );
    }

    return (
        <Card className={`mt-3 ${cardBorder}`}>
            {getTokenBody()}
            {getButtons()}
            <Card.Footer>
                <small>{getFooter()} {getNewBadge()}</small>
            </Card.Footer>
        </Card>
    )
}