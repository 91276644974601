import { EventTask } from "../event/eventTask";
import { isObject } from "../validation";
import { ParticipantEventActivity } from "./participantEventActivity";

export class ParticipantEventTask extends ParticipantEventActivity {
    setTask(task){
        this.setActivity(task);
        return this;
    }
    getTask(){
        return this.getActivity();
    }
    getClone() {
        return new ParticipantEventTask().applyExport(JSON.parse(this.getExport()));
    }

    setActivity(activity) {
        if (!(activity instanceof EventTask)) {
            if (!isObject(activity)){
                throw new ParticipantEventTaskError("Invalid parameter type: task");
            }
            activity = new EventTask().applyExport(activity);
        }
        return super.setActivity(activity);
    }
}

export class ParticipantEventTaskError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventTaskError";
    }
}