import { Button, Card, Stack } from "react-bootstrap";
import { EventDefinition } from "../logic/event/eventDefinition";
import { formatTimestamp, getEventBaseUrl, getEventViewUrl } from "../logic/helper";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { joinAction, leaveAction } from "../logic/eventActions";
import { registerNotification } from "../logic/database";

export function EventCard({ eventData, eventJoined = true }) {
    const navigate = useNavigate();
    const [leaveModalVisible, setLeaveModalVisible] = useState(false);

    const viewEvent = () => {
        navigate(getEventViewUrl(eventData.getId()));
    }

    const joinEvent = () => {
        joinAction({ params: { eventId: eventData.getId() } }).then(() => {
            viewEvent();
        }).catch((error) => {
            registerNotification({
                id: `${eventData.getId()}-join-failed`,
                variant: "danger",
                title: "Unable to join the event",
                text: `Looks like there was a problem joining. ${error.statusText || error.message}`,
                delay: 5000,
            }).then(() => {
                navigate();
            })
        });
    }
    const leaveEvent = () => {
        setLeaveModalVisible(true);
    }
    const leaveConfirmation = (wasConfirmed) => {
        setLeaveModalVisible(false);
        if (wasConfirmed) {
            leaveAction({ params: { eventId: eventData.getId() } }).then(() => {
                navigate(getEventBaseUrl(eventData.getId()));
            });
        }
    }

    if (!(eventData instanceof EventDefinition)) { return <></>; }

    const buttons = () => {
        const result = [];
        if (eventJoined) {
            result.push(<Button key="detailsButton" variant="primary" onClick={() => viewEvent()}><i className="bi bi-arrows-fullscreen"></i> Details</Button>);
            result.push(<Button key="leaveButton" variant="danger" onClick={() => leaveEvent()}><i className="bi bi-dash-circle"></i> Leave</Button>);
        } else {
            result.push(<Button key="joinButton" variant="primary" onClick={() => joinEvent()}><i className="bi bi-plus-circle"></i> Join</Button>);
        }
        return result.flat();
    }

    return (
        <>
            <ConfirmationModal visible={leaveModalVisible} title="Leave Event?" text="If you leave this event all local progress will be deleted. Leave anyway?" onClose={leaveConfirmation} />
            <Card className="mt-3">
                <Card.Img variant="top" src={eventData.getImage()} onClick={() => { if (eventJoined) { viewEvent() } }} style={{ cursor: "pointer" }} />
                <Card.Body>
                    <Card.Title as="h2">
                        {eventData.getTitle()}
                    </Card.Title>
                    <Card.Subtitle as="small" className="text-muted">
                        {formatTimestamp(eventData.getStart())} <i className="bi bi-arrow-right-short"></i> {formatTimestamp(eventData.getEnd())}
                    </Card.Subtitle>
                    <Card.Text>
                        {eventData.getDescription()}
                    </Card.Text>
                    <Stack direction="horizontal" gap={2}>
                        {buttons()}
                    </Stack>
                </Card.Body>
            </Card>
        </>
    );
}