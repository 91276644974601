import React from 'react';
import ReactDOM from 'react-dom/client';

/* Bootstrap */
import "./index.scss";
import "./theme.default.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Col, Container, Row, ThemeProvider } from 'react-bootstrap';
import { Footer } from './components/Footer';

/* Router */
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Loader as RootLaoder, Root } from './routes/Root';
import { ErrorPage } from './ErrorPage';
import { Editor } from './routes/Editor';
import { Welcome } from './routes/Welcome';
import { Loader as RequireRegistrationLoader, RequireRegistration } from './components/RequireRegistration';
import { Loader as EventPreviewLoader, EventPreview } from './routes/EventPreview';
import { Loader as EventViewLoader, EventView } from './routes/EventView';
import { Loader as HomepageLoader, Homepage } from './routes/Homepage';
import { Header } from './components/Header';
import { Privacy } from './routes/Privacy';
import { Terms } from './routes/Terms';
import { taskStartAction, tokenCollectAction, rewardConfirmedAction, taskCompleteAction, tokenFindAction } from './logic/eventActions';
import { Loader as ActionRedirectLoader, ActionRedirect } from './components/ActionRedirect';

/* Needs to overwrite the default path to avoid unwanted external library hosting. */
import { setZXingModuleOverrides } from '@yudiel/react-qr-scanner';
import { getLocation } from './logic/helper';
setZXingModuleOverrides({
  locateFile: (path, prefix) => {
    return `${getLocation()}/js/${path}`;
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: RootLaoder,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Homepage />,
        loader: HomepageLoader,
      },
      {
        path: "privacy",
        element: <Privacy />
      },
      {
        path: "tos",
        element: <Terms />
      },
      {
        path: "edit",
        element: <Editor />,
      },
      {
        path: "u",
        element: <RequireRegistration mustBeRegistered={false} target='/' />,
        loader: RequireRegistrationLoader,
        children: [
          {
            path: "welcome",
            element: <Welcome />,
          }
        ]
      },
      {
        path: "p",
        element: <RequireRegistration />,
        loader: RequireRegistrationLoader,
        children: [
          {
            path: "event/:eventId",
            children: [
              {
                path: "",
                element: <EventPreview />,
                loader: EventPreviewLoader
              },
              {
                path: "view",
                element: <EventView />,
                loader: EventViewLoader
              },
              {
                path: "start/:taskId",
                element: <ActionRedirect redirectTarget='eventView' action={taskStartAction} />,
                loader: ActionRedirectLoader,
              },
              {
                path: "complete/:taskId/:secret",
                element: <ActionRedirect redirectTarget='eventView' action={taskCompleteAction} />,
                loader: ActionRedirectLoader,
              },
              {
                path: "find/:tokenId",
                element: <ActionRedirect redirectTarget='eventView' action={tokenFindAction} />,
                loader: ActionRedirectLoader,
              },
              {
                path: "collect/:tokenId/:secret",
                element: <ActionRedirect redirectTarget='eventView' action={tokenCollectAction} />,
                loader: ActionRedirectLoader,
              },
              {
                path: "confirm/:rewardId/:secret",
                element: <ActionRedirect redirectTarget='eventView' action={rewardConfirmedAction} />,
                loader: ActionRedirectLoader,
              },
            ]
          }
        ]
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider>
      <Container as="main" fluid="md" className="d-flex flex-column min-vh-100">
        <Row>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row className="wrapper flex-grow-1">
          <Col>
            <RouterProvider router={router} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  </React.StrictMode>
);