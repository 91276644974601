import { Col, Form, Row } from "react-bootstrap";
import { FullPageContainer } from "../components/FullPageContainer";
import { useState } from "react";
import { Participant } from "../logic/participant/participant";
import { updateParticipantData } from "../logic/database";
import { RoundIconButton } from "../components/RoundIconButton";
import { useNavigate } from "react-router-dom";

export function Welcome() {
  const [name, setName] = useState("");
  const [acceptedTOS, setAcceptedTOS] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    const isValid = event.currentTarget.checkValidity()
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (isValid && acceptedTOS) {
      const p = new Participant();
      if (name.length <= 0) {
        p.setName("Default");
      } else {
        p.setName(name);
      }
      updateParticipantData(p).then(() => {
        navigate(-1);
      });
    }
  }

  const acceptTosCheckboxLabel = <>I accept the <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.</>

  return (
    <FullPageContainer>
      <div>
        <h1 className="fs-1">
          Welcome to EventQuest
        </h1>
        <div className="fs-5 text-wrap">Looks like you found an event. Join us and earn rewards!</div>

        <Form className="pt-5" onSubmit={handleSubmit} noValidate validated={validated}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check required label={acceptTosCheckboxLabel} feedback="You must agree before you can use this page." feedbackType="invalid" checked={acceptedTOS} onChange={(e) => { return setAcceptedTOS(e.target.checked) }} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-1">
            <Col>
              <Form.Group>
                <Form.Control type="text" value={name} placeholder="Enter your name to get started" onChange={(e) => setName(e.target.value)} />
              </Form.Group>
            </Col>
            <Col xs="1">
              <RoundIconButton label="Confirm Name" type="submit">
                <i className="bi bi-chevron-right"></i>
              </RoundIconButton>
            </Col>
          </Row>
          <Row>
            <Col className="text-muted">
              This is just for you, if none is set it will be defaulted.
            </Col>
          </Row>
        </Form>
      </div>
    </FullPageContainer >
  );
}