import { EventId } from "./eventId";
import { isInteger, isString } from "../validation";
import { getEventConfirmRewardUrl, getLocation, getUUID } from "../helper";

export class EventReward extends EventId {
    #applyExport = false; // Can be used to ignore validations during clone / restoration

    constructor() {
        super();
        this.secret = getUUID();
        this.targetPoints = 1;
        this.title = "";
        this.description = "";
    }

    isSecret(secret) {
        return this.secret === secret;
    }
    getTargetPoints() {
        return this.targetPoints;
    }
    getTitle() {
        return this.title;
    }
    getDescription() {
        return this.description;
    }
    getExport() {
        return JSON.stringify(this);
    }
    getClone() {
        return new EventReward().applyExport(JSON.parse(this.getExport()));
    }
    getCompleteActivityLink(eventId) {
        return `${getLocation()}${getEventConfirmRewardUrl(eventId, this.getId(), this.secret)}`;
    }

    applyExport(params = { id: "", targetPoints: 0, title: "", description: "", secret: "" }) {
        this.#applyExport = true;

        this.setId(params.id);
        this.setTargetPoints(params.targetPoints);
        this.setTitle(params.title);
        this.setDescription(params.description);
        this.setSecret(params.secret);

        this.#applyExport = false;
        return this;
    }
    setTargetPoints(targetPoints = 0) {
        if (!isInteger(targetPoints)) {
            throw new EventRewardError("Invalid parameter type: targetPoints");
        }
        this.targetPoints = targetPoints;
        return this;
    }
    setTitle(title = "") {
        if (!isString(title)) {
            throw new EventRewardError("Invalid parameter type: title");
        }
        this.title = title;
        return this;
    }
    setSecret(secret = "") {
        if (!isString(secret)) {
            throw new EventRewardError("Invalid parameter type: secret");
        }
        if (!this.#applyExport && this.secret.length > 0) {
            throw new EventRewardError("Secret already set");
        }
        this.secret = secret;
        return this;
    }
    setDescription(description = "") {
        if (!isString(description)) {
            throw new EventRewardError("Invalid parameter type: description");
        }
        this.description = description;
        return this;
    }
}

export class EventRewardError extends Error {
    constructor(message) {
        super(message);
        this.name = "EventRewardError";
    }
}