import { Button, Stack } from "react-bootstrap";

export function TaskActionBar({ totalTasks, onCreateClick, onDeleteAllClick }) {
    let hideDeleteButton = "";
    if (totalTasks <= 0) {
        hideDeleteButton = "d-none";
    }
    return (
        <Stack gap={2} direction="horizontal" className="mb-3">
            <Button variant="primary" onClick={() => onCreateClick()}><i className="bi bi-plus-circle"></i> Add a task</Button>
            <Button variant="danger" onClick={() => onDeleteAllClick()} className={hideDeleteButton}><i className="bi bi-trash"></i> Delete all tasks</Button>
        </Stack>
    )
}  