import { ParticipantEventStatus } from "./participantEventStatus";
import { isNull, isObject, isString } from "../validation";
import { EventActivity } from "../event/eventActivity";

export class ParticipantEventActivity {
    constructor() {
        this.activity = null; //EventTask / EventToken depending on implementation
        this.status = null; //ParticipantEventStatus
        this.image = ""; //Base64 image
    }
    getId() {
        return this.getActivity().getId();
    }
    getActivity() {
        if (isNull(this.activity)) {
            throw new ParticipantEventActivityError("No activity exists yet");
        }
        return this.activity;
    }
    getStatus() {
        if (isNull(this.status)) {
            throw new ParticipantEventActivityError("No status exists yet");
        }
        return this.status;
    }
    hasImage() {
        return !!this.image && this.image.length > 0;
    }
    getImage() {
        return this.image;
    }
    getExport() {
        return JSON.stringify(this);
    }

    applyExport(params = { activity: null, status: null, image: "" }) {
        this.setActivity(params.activity);
        this.setStatus(params.status);
        this.setImage(params.image || ""); // Fallback for old events
        return this;
    }
    setActivity(activity) {
        if (!(activity instanceof EventActivity)) {
            throw new ParticipantEventActivityError("Invalid parameter type: activity");
        }
        this.activity = activity;
        return this;
    }
    setStatus(status) {
        if (!(status instanceof ParticipantEventStatus)) {
            if (!isObject(status)) {
                throw new ParticipantEventActivityError("Invalid parameter type: status");
            }
            status = new ParticipantEventStatus().applyExport(status);
        }
        this.status = status;
        return this;
    }
    setImage(image) {
        if (!isString(image)) {
            throw new ParticipantEventActivityError("Invalid parameter type: image");
        }
        this.image = image;
        return this;
    }
}

export class ParticipantEventActivityError extends Error {
    constructor(message) {
        super(message);
        this.name = "ParticipantEventActivityError";
    }
}