import { Button, Card, Stack } from "react-bootstrap";
import { EventReward } from "../../logic/event/eventReward"

export function RewardCard({ reward, onDeleteClick, onEditClick }) {
    if (!(reward instanceof EventReward)) {
        return (<></>);
    }
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    {reward.getTitle()}
                </Card.Title>
                <Card.Text>
                    {reward.getDescription()}
                </Card.Text>
                <Stack direction="horizontal" gap={2}>
                    <Button variant="primary" onClick={() => onEditClick(reward)}><i className="bi bi-pencil-square"></i> Edit</Button>
                    <Button variant="danger" onClick={() => onDeleteClick(reward.getId())}><i className="bi bi-trash"></i> Delete</Button>
                </Stack>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">Target Points: {reward.getTargetPoints()}</small>
            </Card.Footer>
        </Card>
    )
}  