import { Button, Modal } from "react-bootstrap";

export function ConfirmationModal({ visible, title, text, onClose }) {
    return (
        <Modal show={visible} onHide={() => { onClose(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { onClose(true) }}>
                    <i className="bi bi-check-lg"></i> Yes
                </Button>
                <Button variant="secondary" onClick={() => { onClose(false) }}>
                    <i className="bi bi-x-lg"></i> No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}